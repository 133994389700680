
































































































import { Component, Vue, Watch } from "vue-property-decorator";

import api from "@/api";
import { ProductSkuDto } from "@/api/appService";

@Component({
  name: "SetAvailableStock",
})
export default class SetAvailableStock extends Vue {
  loading = true;
  show = false;
  index = 0;
  form: ProductSkuDto[] = [
    {
      availableStock: undefined,
      salePrice: undefined,
      memberPrice: undefined,
      minPurchaseQuantity: undefined,
      id: 0,
    },
  ];

  get title() {
    return (this as any).$l.getLanguageText(
      "salesManagement.goodsOnShelf"
    ) as string;
  }

  save() {
    if (this.form) {
      let count = 0;
      this.form.forEach((item) => {
        if (Number(item.salePrice) <= 0) {
          count++;
        }
      });
      if (count > 0) {
        this.$message.warning(
          (this as any).$l.getLanguageText("saleManagement.SalePrice") as string
        );
      } else {
        api.productSku
          .batchOnShelf({
            body: this.form,
          })
          .then(() => {
            this.show = false;
            this.$message.success(
              (this as any).$l.getLanguageText(
                "basic.updateCompleted"
              ) as string
            );
            this.$emit("onSave");
          });
      }
    }
  }

  @Watch("show")
  async onShowChange() {
    this.loading = !this.form;
  }

  cancel() {
    this.show = false;
  }
}
