

































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";

import api from "@/api";
import {ElForm} from "element-ui/types/form";

@Component({
  name: "NumberOfStores",
})
export default class NumberOfStores extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({required: true})
  dataId!: number;
  loading = true;
  show = false;
  form = {
    id: undefined,
    quantity: undefined
  };
  detail = {
    fkProductName: undefined,
    fkProductNumber: undefined,
    specParamMappingStr: undefined,
    totalStock: undefined,
  }

  save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      if (this.form.id){
        debugger
      }
      if (this.dataId) {
        api.productSku
          .setAvailableStock({
            body: {
              id: this.form.id,
              quantity: this.form.quantity,
            },
          })
          .then(() => {
            this.show = false;
            this.$message.success((this as any).$l.getLanguageText('basic.updateCompleted') as string);
            this.$emit("onSave");
          });
      }
    });
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (this.dataId) {
      this.loading = true;
      await api.productSku
        .get({id: this.dataId})
        .then((res: any) => {
          this.detail.fkProductName = res!.fkProduct.name;
          this.detail.fkProductNumber = res!.number;
          this.detail.specParamMappingStr = res!.specParamMappingStr;
          this.detail.totalStock = res!.totalStock;
          console.log(this.detail)
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  linkRule = {
    quantity: [
      {
        required: true,
        message: (this as any).$l.getLanguageText('salesManagement.quantity') as string,
        trigger: "blur",
      },
    ],
  };



  cancel() {
    this.show = false;
  }

}
