































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";

import api from "@/api";
import {ElForm} from "element-ui/types/form";
import {ProductSkuDto} from "@/api/appService";

@Component({
  name: "OrderOf",
})
export default class OrderOf extends Vue {
  @Ref() readonly dataForm!: ElForm;

  loading = true;
  show = false;
  form = {
    emailAddress: undefined,
    customerCode: undefined
  };


  save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      if (this.form) {
        api.orderMain
          . preparePlaceOrderOnBehalf({
            body: {
              emailAddress: this.form.emailAddress,
              customerCode: this.form.customerCode,
            },
          })
          .then((res) => {
            console.log(res)
            this.show = false;
            this.$message.success((this as any).$l.getLanguageText('salesManagement.onSave') as string);
            this.$emit("onSave");
            this.jumpPricingDetail(this.form.emailAddress,this.form.customerCode)
          });
      }
    });
  }

  jumpPricingDetail(email:any,code:any) {
    this.$router.push({
      name: "startOrderOf",
      params: {email: email.toString(),code:code.toString()},
    });
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    this.form.emailAddress=undefined;
    this.form.customerCode=undefined;
    this.loading = false;
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  linkRule = {
    emailAddress: [
      {
        required: true,
        message: (this as any).$l.getLanguageText('salesManagement.mailbox') as string,
        trigger: "blur",
      },
    ],
    customerCode: [
      {
        required: true,
        message: (this as any).$l.getLanguageText('salesManagement.systemCode') as string,
        trigger: "blur",
      },
    ],
  };



  cancel() {
    this.show = false;
  }
  get title() {
    return (this as any).$l.getLanguageText('salesManagement.orderOf') as string;
  }
}
