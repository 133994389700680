







































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView.vue";
import api from "@/api";
import {
  ProductCategoryDto,
  ProductSkuDto,
  ProductSkuStatus,
} from "@/api/appService";
import NumberOfStores from "@/views/productSku/salesManagement/numberOfStores.vue";
import SetAvailableStock from "@/views/productSku/salesManagement/setAvailableStock.vue";
import OrderOf from "@/views/productSku/salesManagement/orderOf.vue";
import { createNgTree, INgNode } from "@/utils/tree";

@Component({
  name: "productSkuList",
  components: {
    OrderOf,
    SetAvailableStock,
    NumberOfStores,
    PagedTableView,
  },
})
export default class SalesList extends Vue {
  queryForm = {
    name: undefined,
    model: undefined,
    parentIdPath: undefined,
    brandId: undefined,
    isRecommend: undefined,
    minAvailableStock: undefined,
    maxAvailableStock: undefined,
    status: "",
  };
  categories: ProductCategoryDto[] = [];
  categoriesTree: INgNode<ProductCategoryDto>[] = [];
  productSkuId = 0;
  parentIdPathList: any = [];
  brandIdList: any = [];
  selectionList: any = [];
  productSkuIdList: any = [];
  productSkuList: ProductSkuDto[] = [];

  // 获取表数据
  created() {
    this.fetchData(this.queryForm);
    this.fetchBrandIdList();
    this.fetchParentIdPathList();
    this.fetchCategories();
  }

  fetchData(params: any) {
    return api.productSku.getAll(params);
  }

  async fetchBrandIdList() {
    await api.productBrand.getAll({ maxResultCount: 1000 }).then((res) => {
      this.brandIdList = res.items!;
    });
  }

  async fetchParentIdPathList() {
    await api.productCategory.getAll({ maxResultCount: 1000 }).then((res) => {
      this.parentIdPathList = res.items!;
    });
  }

  jumpDetail(index: number, row: ProductSkuDto) {
    this.productSkuId = row.id!;
    this.$router.push({
      name: "salesManagementDetail",
      params: { id: row.id!.toString() },
    });
  }

  async removedFromShelf(index: number, row: ProductSkuDto) {
    if (row.id) {
      this.productSkuIdList = [];
      this.productSkuIdList?.push(row.id!);
    }
    await api.productSku.batchRemovedFromShelf({
      body: { idList: this.productSkuIdList },
    });
    this.$message.success(
      (this as any).$l.getLanguageText(
        "salesManagement.removedSuccessfully"
      ) as string
    );
  }

  async handleBatchRemovedFromShelf() {
    let _this = this as any;
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: (this as any).$l.getLanguageText(
          "salesManagement.atLeastOnePieceOfData"
        ) as string,
      });
      return;
    }
    let list = this.selectionList.filter(
      (m: ProductSkuDto) => m.status !== ProductSkuStatus.RemovedFromShelf
    );
    if (!list || list.length <= 0) {
      this.$message({
        type: "error",
        message: (this as any).$l.getLanguageText(
          "salesManagement.atLeastOneDelistedOrUnlistedData"
        ) as string,
      });
      return;
    }
    if (this.selectionList) {
      let ids: any = [];
      this.selectionList.forEach((item: any) => {
        ids.push(item.id);
      });
      this.productSkuIdList = ids;
      debugger;
    }
    await api.productSku.batchRemovedFromShelf({
      body: { idList: this.productSkuIdList },
    });
    this.$message.success(
      _this.$l.getLanguageText("salesManagement.bulkOffShelves")
    );
  }

  handleSetAvailableStock(index: number, row: ProductSkuDto) {
    if (row.id) {
      this.productSkuList = [];
      if (!row.minPurchaseQuantity || row.minPurchaseQuantity === 0) {
        row.minPurchaseQuantity = 1;
      }
      this.productSkuList.push(row);
    }
    (this.$refs.setAvailableStock as any).form = this.productSkuList;
    (this.$refs.setAvailableStock as any).show = true;
  }

  handleBatchSetAvailableStock() {
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: (this as any).$l.getLanguageText(
          "salesManagement.atLeastOnePieceOfData"
        ) as string,
      });
      return;
    }

    let list = this.selectionList.filter(
      (m: ProductSkuDto) => m.status !== ProductSkuStatus.OnShelf
    );
    if (!list || list.length <= 0) {
      this.$message({
        type: "error",
        message: (this as any).$l.getLanguageText(
          "salesManagement.atLeastOneListedOrUnlistedData"
        ) as string,
      });
      return;
    }
    (this.$refs.setAvailableStock as any).form = list.map(
      (item: ProductSkuDto) => {
        if (!item.minPurchaseQuantity || item.minPurchaseQuantity === 0) {
          item.minPurchaseQuantity = 1;
        }
        return item;
      }
    );
    (this.$refs.setAvailableStock as any).show = true;
  }

  jumpPricingDetail(index: number, row: ProductSkuDto) {
    this.$router.push({
      name: "pricingManagementDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 获取已选择数据
  getSelectionList(rows: ProductSkuDto) {
    this.selectionList = rows;
  }

  handleNumberOfStores(index: number, row: ProductSkuDto) {
    this.productSkuId = row!.id!;
    (this.$refs.numberOfStores as any).show = true;
    (this.$refs.numberOfStores as any).form = row;
  }

  handleOrderOf() {
    // (this.$refs.orderOf as any).show = true;
    window.open((this as any).portalLoginUrl, "_blank");
  }

  fetchCategories() {
    api.productCategory.getAll({ maxResultCount: 1000 }).then((res) => {
      this.categories = res.items!;
      this.categoriesTree = createNgTree(
        res.items!,
        "parentId",
        "id",
        null,
        "children",
        null,
        false,
        "id",
        "name"
      );
    });
  }

  handleCategoriesChange(value: any) {
    this.queryForm!.parentIdPath = value![value.length - 1];
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }

  validationChoose() {
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: (this as any).$l.getLanguageText(
          "salesManagement.atLeastOnePieceOfData"
        ) as string,
      });
      return;
    }
  }

  setSalePriceVisible(row: ProductSkuDto) {
    api.productSku.setRecommend({ body: { id: row.id } });
  }
}
